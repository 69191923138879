<template>
  <v-card
    :elevation="elevation"
    :outlined="outlined"
    class="rounded-lg"
    :to="path.to"
    :href="path.href"
  >
    <div style="position: relative">
      <v-img
        :src="party.cover"
        class="rounded-lg rounded-b-0"
        style="cursor: pointer"
        :aspect-ratio="16 / 9"
        :style="{
          filter: !active && timeStatus ? `grayscale(90%)` : undefined,
        }"
      >
        <template v-slot:placeholder>
          <v-card elevation="0" class="h-full">
            <v-skeleton-loader
              type="image"
              class="rounded-lg rounded-b-0 h-100"
              height="100%"
            />
          </v-card>
        </template>
      </v-img>
      <div
        style="position: absolute; top: 10px; left: 10px"
        class="d-flex flex-column align-left"
      >
        <div v-if="!active && timeStatus">
          <v-chip color="secondary" small>
            <v-icon x-small left>mdi-clock-outline </v-icon>
            Encerrado
          </v-chip>
        </div>
        <div v-if="party.private">
          <v-chip color="info" x-small>
            <v-icon x-small left>mdi-account-lock</v-icon>
            Privado
          </v-chip>
        </div>
        <div v-if="party.isFree">
          <v-chip color="success" x-small>
            <v-icon x-small left>mdi-ticket</v-icon>
            Gratuito
          </v-chip>
        </div>
      </div>
      <div
        v-if="party?.Organization?.Platform"
        style="position: absolute; top: 10px; right: 10px; bottom: 10px"
        class="d-flex flex-column align-left"
      >
        <v-chip :color="party.Organization.Platform.colors.appBar" small>
          <div>
            <v-img
              v-if="party.Organization.Platform.logos?.light"
              :src="party.Organization.Platform.logos.light"
              height="20px"
              max-width="54px"
              contain
            />
          </div>
        </v-chip>
      </div>
    </div>

    <div class="d-flex gap-4 pa-3">
      <div class="d-flex flex-column align-left">
        <v-card
          outlined
          class="rounded-lg d-flex flex-column align-center"
          style="width: 50px"
        >
          <div
            class="d-flex rounded-t-lg w-full flex-column align-center justify-center white--text"
            :class="!active && timeStatus ? 'secondary' : 'primary'"
            style="min-height: 26px"
          >
            <div class="font-weight-medium">
              {{ party.date | date("MMM").toUpperCase() }}
            </div>
            <span
              style="margin-top: -4px; margin-bottom: 1px; font-size: 9px"
              v-if="moment().year() !== moment(party.date).year()"
            >
              {{ party.date | date("YYYY") }}
            </span>
          </div>
          <div
            class="w-full text-center rounded-b-lg d-flex flex-column align-center justify-center"
            :style="{
              backgroundColor: $vuetify.theme.dark ? '#121212' : '#f2f3f8',
              minHeight: '30px',
            }"
          >
            <span class="text-18 font-weight-bold">
              {{ party.date | date("DD") }}
            </span>
          </div>
        </v-card>
      </div>
      <div class="w-full">
        <h5 class="font-weight-bold mb-0">
          {{ party.name }}
        </h5>
        <div class="d-flex flex-column gap-x-1 justify-space-between">
          <span style="cursor: pointer">
            {{ party.Organization.name }}
          </span>
          <span class="text-body-2 mb-1" v-if="party.Address">
            <v-icon small left>mdi-map-marker</v-icon>{{ party.Address.city }} -
            {{ party.Address.state }}
          </span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import PartyStatus from "../../utils/partyStatus";
import moment from "moment";
export default {
  methods: {
    goToOrg() {
      this.$router.push({
        name: "shop.organization",
        params: {
          orgSlug: this.party.Organization.slug,
        },
      });
    },
    goToParty() {
      this.$router.push({
        name: "shop.party",
        params: {
          orgSlug: this.party.Organization.slug,
          partyId: this.party.slug || this.party.id,
        },
      });
    },
  },
  computed: {
    path() {
      const path = `/shop/${this.party.Organization.slug}/${
        this.party.slug || this.party.id
      }`;

      if (this.party?.Organization?.Platform)
        return { href: `//${this.party.Organization.Platform.domain}${path}` };
      return { to: path };
    },
    active() {
      const status = new PartyStatus(this.party);
      return status.isActive;
    },
    moment() {
      return moment;
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
    elevation: {
      type: [String, Number],
      default: 0,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    timeStatus: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style></style>
